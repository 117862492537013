import $ from "jquery"; //3.5.1

import Swiper from 'swiper/bundle';
//import Swiper, { Navigation, Pagination } from 'swiper';
//Swiper.use([Navigation, Pagination]);
import 'swiper/swiper-bundle.css'; //v6.4.xxx
//import 'swiper/css/swiper.min.css'; //v5.4.5

import picturefill from "picturefill";
import LazyLoad from "vanilla-lazyload";

require('fslightbox'); //Lightbox
const imagesLoaded = require('imagesloaded'); //プリロード

// [CSS]
import "../scss/app.scss";

const body = document.body;
const lbox = new FsLightbox();

let device_ratio = 1;



(function () {
  let lazyLoadInstance = new LazyLoad();

  if(window.devicePixelRatio) {
    device_ratio = window.devicePixelRatio;
  }

  initPreload();
  //MatchHeight.init()

  picturefill();
  initHeader();
  initSwiper();

  if(document.getElementById('site-home')) {
    //initModuleTab();
  }

  // ____COMMON____
  let body = document.body;
  let navi_btn = document.getElementById('gnavi-btn');
  
  // // #ナビボタン
  // navi_btn.addEventListener('click', function(ev){
  //   ev.preventDefault();
  //   if(!body.classList.contains('open-nav')) {
  //     body.classList.remove('hide-nav');
  //     body.classList.add('open-nav');
  //   }
  //   else {
  //     body.classList.remove('open-nav');
  //     body.classList.add('hide-nav');
  //   }
  // });
})();


/**
 * pre-loading : ローディングバー
 * refer: https://hirakublog.com/code/374/
 */
function initPreload() {
  // [Loading - Progress Bar]
  const progressBar = document.querySelector('.js-loader-progress-bar--line1')
  const progressBar2 = document.querySelector('.js-loader-progress-bar--line2')
  const progressNumber = document.querySelector('.js-loader-progress-number')
  
  const imgLoad = imagesLoaded('body');//body内の画像を監視
  const imgTotal = imgLoad.images.length;//画像の総数を取得
  
  let imgLoaded = 0;//ロードした画像カウント
  let progressSpeed = 8;//プログレスバーの進むスピード 大きいほど速くなる
  let progressCount = 0;//ローディングの進捗状況 0〜100
  let progressResult = 0;//ローディングの進捗状況 0〜100
  let progressInit;

  if(!imgTotal) {
    progressBar.style.width = '100%';
    progressNumber.innerText = 100;
    setTimeout(function () {
      document.querySelector('body').classList.add('is-loaded');
    }, 800);
  }
  else {
    //読み込み状況をアップデート
    progressInit = setInterval(function () {
      updateProgress();
    }, 25);
    
    //画像を一つロードするたびにimgLoadedのカウントをアップ
    imgLoad.on('progress', function (instance, image) {
      imgLoaded++
    })
  }
  
  //読み込み状況アップデート関数
  function updateProgress() {
    //ローディング演出用の計算
    progressCount += (imgLoaded / imgTotal) * progressSpeed;
  
    if(progressCount >= 100 && imgTotal > imgLoaded) {
      //カウントは100以上だが画像の読み込みが終わってない
      progressResult = 99;
    } else if(progressCount > 99.9) {
      //カウントが100以上になった
      progressResult = 100;
    } else {
      //現在のカウント
      progressResult = progressCount;
    }
  
    //ローディング進捗状況をプログレスバーと数字で表示
    progressBar.style.width = progressResult + '%';
    progressBar2.style.width = progressResult + '%';
    progressNumber.innerText = (Math.floor(progressResult));
    
    //ローディング完了後 0.8秒待ってページを表示
    if (progressResult >= 100 && imgTotal == imgLoaded) {
      clearInterval(progressInit);
      setTimeout(function () {
        document.querySelector('body').classList.add('is-loaded');
      }, 800);
    }
  }
}


function num2kanji(_num){
  let num = String(_num);
  let txt = new Array("〇","一","二","三","四","五","六","七","八","九");
  let num_kanji = "";
  let c="";
  for(var i=0; i<num.length; i++){
    c = txt[eval(num.charAt(i))];
    num_kanji += c;
  }
  return num_kanji;
}


function initSwiper() {
  //  カード用パラメータ
  let param_card = {
    loop: true,
    centeredSlides: true,
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 12,
    breakpoints: {
      768: {
        loop: false,
        centeredSlides: false,
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 15,
      },
      840: {
        loop: false,
        centeredSlides: false,
        slidesPerView: 3,
        slidesPerGroup: 3,
        spaceBetween: 30,
      }
    },
    pagination: {
      el: ".func-pagination",
      type: 'custom',
      renderCustom: function (swiper, current, total) {
        return current + ' of ' + total; 
      }
    },
    navigation: {
      nextEl: ".func-arrow--next",
      prevEl: ".func-arrow--prev"
    }
  };

  let param_basic = {
    speed: 1000,
    slidesPerView: 1,
    effect: 'fade',
    loop: false,
    loopAdditionalSlides: 0,
    preventInteractionOnTransition: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    /* pagination: {
      el: '.p-fv-slider__pagination',
      clickable: true,
      type: 'bullets',
      renderBullet: (index, className) => {
        //各articleからカテゴリーの色を定義
        let _article = top_slider.querySelectorAll('.p-toparticle')[index];
        let _circle_color = '';
        if(_article.classList.contains('_type-experiment')) {
          _circle_color = ' _color-experiment';
        }
        else if(_article.classList.contains('_type-note')) {
          _circle_color = ' _color-note';
        }
        else if(_article.classList.contains('_type-favorite')) {
          _circle_color = ' _color-favorite';
        }
        return '<span class="' + className + _circle_color+'"><span class="swiper-pagination-bullet__number">0' + (Number(index) + 1) + '</span></span>';
      },
    } */
  }
  

  // [HOME]
  if(document.getElementById('site-home')) {
    /*
     * 参考
     * https://www.evoworx.co.jp/blog/swiper-customize/
     * https://codepen.io/mizuno_evo/pen/mdrZxEK
     * */

    // ##メインビジュアル##
    let top_slider = document.querySelector('.p-topvisual-slider');
    // パラメータ
    let _speed = 500,
        _autoplay_delay = 5000,
        _autoplay_delay_ajust = _autoplay_delay - _speed,
        _circle_size = 48,
        _circle_center = _circle_size * .5;
        
    
    let swiper_topvisual = new Swiper('.p-topvisual-slider__swiper', {
      speed: _speed,
      slidesPerView: 1,
      effect: 'fade',
      loop: false,
      loopAdditionalSlides: 0,
      preventInteractionOnTransition: true,
      autoplay: {
        delay: _autoplay_delay,
        disableOnInteraction: false,
      },
      pagination: {
        el: '.p-topvisual-slider__pagination',
        clickable: true,
        type: 'bullets',
        renderBullet: (index, className) => {
          //各articleからカテゴリーの色を定義
          let _article = top_slider.querySelectorAll('.p-toparticle')[index];
          let _circle_color = '';
          if(_article.classList.contains('_type-experiment')) {
            _circle_color = ' _color-experiment';
          }
          else if(_article.classList.contains('_type-note')) {
            _circle_color = ' _color-note';
          }
          else if(_article.classList.contains('_type-favorite')) {
            _circle_color = ' _color-favorite';
          }
          return '<span class="' + className + _circle_color+'"><span class="swiper-pagination-bullet__number">0' + (Number(index) + 1) + '</span></span>';
          // [サークルの生成]
          //return '<span class="' + className + '"><span class="swiper-pagination-bullet__number">0' + (Number(index) + 1) + '</span><svg class="circle'+_circle_color+'" width="'+_circle_size+'" height="'+_circle_size+'" viewBox="0 0 '+_circle_size+' '+_circle_size+'"><circle class="circle2" cx="'+_circle_center+'" cy="'+_circle_center+'" r="'+(_circle_center-1)+'" stroke="#fff" stroke-width="2" fill="none"/><circle class="circle1" cx="'+_circle_center+'" cy="'+_circle_center+'" r="'+(_circle_center-1)+'" stroke="#ff0000" stroke-width="2" fill="none"/></svg></span>';
        },
      }
    });
    swiper_topvisual.on('slideChange', () => {
      if (swiper_topvisual.realIndex > 0) {
        swiper_topvisual.params.autoplay.delay = _autoplay_delay_ajust; 
      }
    });


    // ##最新の記事##
    let _latest_param = {...param_card};
    _latest_param.pagination.el = '.p-latest__func-pagination';
    _latest_param.navigation.nextEl = '.p-latest__func-arrow--next';
    _latest_param.navigation.prevEl = '.p-latest__func-arrow--prev';
    let swiper_card_latest = new Swiper('.latest-swiper', _latest_param);

    // ##読まれている記事##
    let _popular_param = {...param_card};
    _popular_param.pagination.el = '.p-popular__func-pagination';
    _popular_param.navigation.nextEl = '.p-popular__func-arrow--next';
    _popular_param.navigation.prevEl = '.p-popular__func-arrow--prev';
    let swiper_card_popular = new Swiper('.popular-swiper', _popular_param);
  }

  // [少女ページ]
  else if(document.getElementById('site-girls')) {
    

    // パラメータ
    let _param = {...param_basic};

    // #メインビジュアル
    let swiper_fv = new Swiper('.p-fv-slider__swiper', _param);
    // #各拠点
    let swp_living = new Swiper('.swiper-living', _param);
    let swp_niniroom = new Swiper('.swiper-niniroom', _param);
    let swp_ywca = new Swiper('.swiper-ywca', _param);
    let swp_happiness = new Swiper('.swiper-happiness', _param);
    let swp_minami = new Swiper('.swiper-minami', _param);
  }
}





function initModuleTab(target_tab = document.querySelector('.mdl_tab'), target_index = 1) {
  if(!target_tab) return false;

  let tabHead = target_tab.querySelector('.mdl_tab-head');
  let tabBody = target_tab.querySelector('.mdl_tab-body');
  let tabBody_items = tabBody.querySelectorAll('.mdl_tab-body__item');
site-single
  let tab_inputs =  tabHead.querySelectorAll('input');
  let _headIndex = 1;
  let _bodyIndex = 1;

  let coverimage = document.querySelector('.sct-saijiki .cover');
  
  //body側のindex付与
  tabBody_items.forEach(tabBody_item => {
    tabBody_item.setAttribute('data-index', _bodyIndex);
    tabBody_item.classList.add('mdl_tab-body__item--index'+_bodyIndex);
    if(target_index == _bodyIndex) {
      tabBody_item.classList.add('__show-tabitem');
    }
    _bodyIndex++
  });

  //切り替えボタン
  tab_inputs.forEach(tab_input => {
    tab_input.setAttribute('data-index', _headIndex++); //head側のindex付与
    tab_input.addEventListener('change', (ev) => {
      let myindex = ev.target.getAttribute('data-index');
      tabBody_items.forEach(tabBody_item => {
        if(tabBody_item.getAttribute('data-index') != myindex) {
          tabBody_item.classList.remove('__show-tabitem')
        }
        else {
          tabBody_item.classList.add('__show-tabitem')
        }
      });

      let month = ev.target.getAttribute('data-month');
      let img_name = 'cover_recipe'+month+'@2x.jpg';
      if(device_ratio >= 2) {
        let img_name = 'cover_recipe'+month+'@2x.jpg';
      }
      
      coverimage.setAttribute('style', 'background-image:url(/views/themes/theme-odagaki/assets/img/home/'+img_name+')');
    });
  });
}


function initHeader() {
  const header = document.getElementById("siteHeader");
  const hH = header.clientHeight;
  let pos = 0;
  let lastPos = 0;
  const winH = window.innerHeight;
  const docH = document.documentElement.scrollHeight;
  const windBtm = docH - winH;
  const isHome = document.body.classList.contains('home');
  
  const onScroll = () => {
    if(pos > hH && pos > lastPos) {
      header.classList.add('__unpinned');
    }
    if(pos < hH || pos < lastPos || windBtm <= pos) {
      header.classList.remove('__unpinned');
    }
    lastPos = pos;
  };

  const onScroll_for_home = () => {
    if(pos < winH) {
      header.classList.add('__hide');
    }
    else {
      header.classList.remove('__hide');
      if((pos > hH && pos > lastPos)) {
        header.classList.add('__unpinned');
      }
      else if(pos < hH || pos < lastPos || windBtm <= pos) {
        header.classList.remove('__unpinned');
      }
    }
    lastPos = pos;
  };

  window.addEventListener("scroll", () => {
    pos = window.scrollY;
    onScroll();
  });


  // #ナビボタン
  const navi_btn = document.getElementById('button-gmenu');
  navi_btn.addEventListener('click', function(ev){
    ev.preventDefault();
		if(!body.classList.contains('is-menu-open')) {
			//body.classList.remove('hide-nav');
			body.classList.add('is-menu-open');
		}
		else {
      body.classList.remove('is-menu-open');
      //body.classList.add('hide-nav');
		}
  });

  
  let $gnavi_links_items = $('.gnavi-links__item');
  $gnavi_links_items.each(function(index, item) {
    let $item = $(item);
    let $_head = $('.has_child-head', $item);
    let $_body = $('.has_child-body', $item);
    
    if($item.hasClass('has_child')) {
      $_body.slideUp(0);
      let $window = $(window);

      $_head.on('click', (ev)=> {
        if($window.innerWidth() <= 896) {
          ev.preventDefault();
          $_body.slideToggle(300);
          $('.has_child-head', $(this)).toggleClass('_is-open');
        }
      });
    }
  });
}

